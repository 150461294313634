import { useEffect } from "react";
import styles from '../../../src/styles/modalStyle.module.scss';

const DeletionConfirmationModal = ({ contentType, setVisibility, visibility, deleteFunction }) => {

    let modalJSX = <div className={visibility ? styles.modalParent : styles.modalParentInvisible}>
            <div className={styles.overlay} onClick={() => setVisibility(false)}></div>
            <div className={styles.modalContainer}>
                <p>Are you sure you want to delete this {contentType}?</p>
                <div className={styles.modalContainer__actions}>
                    <button onClick={() => setVisibility(false)}>No, Go back</button>
                    <button onClick={() => deleteFunction()}>Yes, Delete</button>
                </div>
            </div>
        </div>
    

    useEffect(() => {
        if(!visibility) {
            setTimeout(() => {
                modalJSX = <></>; 
            }, 2000);
        }
    }, [visibility]);

    return (
        <>{modalJSX}</> 
    );
}

export default DeletionConfirmationModal;