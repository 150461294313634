import axios from "axios";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import MemoryKeys from "../components/memoryKeys";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
    const [token, setToken_] = useState(localStorage.getItem(MemoryKeys.UserToken));

    const setToken = (newToken) => {
        setToken_(newToken);
    };

    useEffect(() => {
        if (token) {
            axios.defaults.headers.common["Authorization"] = "Bearer " + token;
            localStorage.setItem(MemoryKeys.UserToken, token);
        } else {
            delete axios.defaults.headers.common["Authorization"];
            localStorage.removeItem(MemoryKeys.UserToken);
        }
    }, [token]);

    const contextValue = useMemo(() => ({
        token, setToken,
    }), [token])

    return (
        <AuthContext.Provider value={contextValue}>
            {children}
        </AuthContext.Provider>
    )
};

export const useAuth = () => {
    return useContext(AuthContext); 
}

export default AuthProvider; 