import { IoMdAddCircleOutline, IoMdCloseCircleOutline } from "react-icons/io";
import styles from "../../styles/editBODInfoPanel.module.scss";
import { useState } from "react";
import {
  useGetKeyStaffById,
  usePutKeyStaff,
} from "../../all-pages/api/apiClient";
import { useEffect } from "react";

const EditKeyStaffInfoPanel = ({ editItemId, visibility, setVisibility }) => {
  const getKeyStaffDataId = useGetKeyStaffById();
  const putKeyStaffData = usePutKeyStaff();
  const [content, setContent] = useState({
    name: "",
    text: "",
    position: "",
    image: "",
  });
  const [updatedContent, setUpdatedContent] = useState({
    name: "",
    text: "",
    position: "",
    image: "",
  });
  const [isEditClick, setIsEditClick] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getKeyStaffDataId(editItemId)
      .then((response) => {
        console.log(response.data);
        setContent(response.data.data);
        setUpdatedContent({
          name: response.data.data.name,
          position: response.data.data.position,
          text: response.data.data.text,
          image: response.data.data.image,
        });
        setImageFile(response.data.data.image);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [isEditClick]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setUpdatedContent((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  };

  // State to check if image is chosen
  const [isImageChosen, setIsImageChosen] = useState(false);

  //   State for image file
  const [imageFile, setImageFile] = useState("");

  //   Error messages states
  const [imageErrorMsg, setImageErrorMsg] = useState(false);
  const [nameErrorMsg, setnameErrorMsg] = useState(false);
  const [positionErrorMsg, setPositionErrorMsg] = useState(false);
  const [textErrorMsg, settextErrorMsg] = useState(false);

  const handleImageChange = (e) => {
    // Log image file chosen
    // console.log(e.target.files[0]);
    // console.log(updatedContent);

    // Set the image url
    if (e.target.files[0]) {
      // Create object url
      const imgURL = URL.createObjectURL(e.target.files[0]);

      //   Set image file
      setImageFile(imgURL);
      //   Update boolean value for image chosen
      setIsImageChosen(true);
      // set updated image
      setUpdatedContent((prevValue) => {
        return {
          ...prevValue,
          ["image"]: e.target.files[0],
        };
      });
      //   Close image error message
      setImageErrorMsg(false);
    }
    // If no file was selected
    else {
      setImageFile(undefined);
      //   Update boolean value for image chosen
      setIsImageChosen(false);
      //   SHow image error message
      setImageErrorMsg(true);
    }
  };

  async function validateFormFields(e) {
    // Prevent default behaviour
    e.preventDefault();

    // If name or description or image is unavailable
    if (
      !content.name ||
      !content.position ||
      !content.text ||
      !imageFile ||
      !updatedContent.name ||
      !updatedContent.position ||
      !updatedContent.text ||
      !updatedContent
    ) {
      !content.name ? setnameErrorMsg(true) : setnameErrorMsg(false);
      !content.position
        ? setPositionErrorMsg(true)
        : setPositionErrorMsg(false);
      !content.text ? settextErrorMsg(true) : settextErrorMsg(false);
      !imageFile ? setImageErrorMsg(true) : setImageErrorMsg(false);
      !updatedContent.name ? setnameErrorMsg(true) : setnameErrorMsg(false);
      !updatedContent.position
        ? setPositionErrorMsg(true)
        : setPositionErrorMsg(false);
      !updatedContent.text ? settextErrorMsg(true) : settextErrorMsg(false);

      return;
    }

    // Else...
    // Run function to update info
    console.log(updatedContent);
    setIsLoading(true);
    await putKeyStaffData(updatedContent, editItemId)
      .then((response) => {
        console.log("response: ", response.data);
        window.location.reload();
        // setIsEditClick(true);
        // navigate("/name-text", { replace: true });
        // setIsEditClick(true);
      })
      .catch((error) => {
        if (error.message === "Network Error") {
          alert("Network error! please check your internet connection");
        } else if (error.response.data.error === "jwt expired") {
          alert(
            "Failed to update content: Login Expired please try login again"
          );
        } else {
          console.log(error);
        }
      })
      .finally((error) => {
        // setLoading(false);
      });
    setVisibility(false);
  }

  return (
    <div className={styles.rightSidebarContainer}>
      <div
        className={styles.overlay}
        onClick={() => setVisibility(false)}
      ></div>
      <div
        className={`${styles.editBoardOfDirectorPanelContainer} ${styles.container}`}
      >
        <div className={styles.topArea}>
          <div className={styles.topArea__lhs}>
            <h4>Edit name and text Information</h4>
          </div>
          <div className={styles.topArea__rhs}>
            <button onClick={() => setVisibility(false)}>
              <IoMdCloseCircleOutline /> Close
            </button>
          </div>
        </div>
        <form className={styles.infoArea} onSubmit={validateFormFields}>
          <div className={styles.fieldControl}>
            <label htmlFor="name">Name:</label>
            <input
              type="text"
              name="name"
              placeholder="Enter Title"
              value={updatedContent.name}
              onChange={handleChange}
            />
            {nameErrorMsg && (
              <span className={styles.errorMsg}>Please enter name</span>
            )}
          </div>
          <div className={styles.fieldControl}>
            <label htmlFor="name">Position:</label>
            <input
              type="text"
              name="position"
              placeholder="Enter Position eg (CEO, Cofounder...)"
              value={updatedContent.position}
              onChange={handleChange}
            />
            {positionErrorMsg && (
              <span className={styles.errorMsg}>
                Please fill this position field
              </span>
            )}
          </div>
          <div className={styles.fieldControl}>
            <label htmlFor="text">Text:</label>
            <textarea
              type="text"
              name="text"
              placeholder="Enter Description"
              value={updatedContent.text}
              onChange={handleChange}
            />
            {textErrorMsg && (
              <span className={styles.errorMsg}>Please enter Mision</span>
            )}
          </div>
          <div className={styles.fieldControl}>
            <div className={styles.imageControl}>
              <div className={styles.imageChosen}>
                <img src={imageFile || content.image} alt="nametextImage" />
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleImageChange}
                />
              </div>
            </div>
            {imageErrorMsg && (
              <span className={styles.errorMsg}>Please choose an image</span>
            )}
          </div>
          <button type="submit">
            {isLoading
              ? "Please wait, uploading content..."
              : "Update information"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default EditKeyStaffInfoPanel;
