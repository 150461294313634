import "./login.scss";
import IntegrityLogo from "../../assets/aaj.png";
import { useLogin } from "../api/apiClient";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import MemoryKeys from "../../components/memoryKeys";
import { useAuth } from "../../provider/authProvider";
import CustomPreloader from "../../components/preloader/preloader";

const Login = () => {
  const { setToken } = useAuth();

  const loginUser = useLogin();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  async function handleUserLogin(e) {
    setErrorMsg("");
    const userData = { userName: email, password };
    console.log(userData);

    e.preventDefault();

    // Validate fields
    if (!email || !password) {
      if (!email) {
        setErrorMsg("Please input your username");
        return;
      }
      if (!password) {
        setErrorMsg("Please input your password");
        return;
      }

      setErrorMsg(undefined);
      return;
    }
    setLoading(true);

    // Send request
    await loginUser(userData)
      .then((response) => {
        console.log(response);
        console.log(response.data.token);

        if (response.data.success) {
          localStorage.setItem(MemoryKeys.UserToken, response.data.token);
          setToken(response.data.token);
          navigate("/admin", { replace: true });
          window.location.reload();
          setLoading(true);

          // setTimeout(() => {
          //   setLoading(true);
          //   navigate("/admin");
          // }, 3000);
        }
        return;
      })
      .catch((error) => {
        if (error.response) {
          setErrorMsg(error.response.data.error);
        } else {
          setErrorMsg(
            <>
              There was an error Logging you in.
              <br /> Check your internet connection
            </>
          );
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <div className="login__container">
      {loading && <CustomPreloader />}
      <div className="logo__container">
        <img src={IntegrityLogo} alt="integrity Logo" />
      </div>
      <form className="login__section" onSubmit={(e) => handleUserLogin(e)}>
        <h3>Login</h3>
        <span></span>
        <div className="f-c input__container">
          <label>Username</label>
          <input value={email} onChange={(e) => setEmail(e.target.value)} />
        </div>
        <div className="f-c input__container">
          <label>Password</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value.trim())}
          />
        </div>
        <div className="login__reset">
          <p>Can't login? Reset Department here</p>
          <p>Forgot Password</p>
        </div>

        {errorMsg && <div className="errorMsg">{errorMsg}</div>}

        <button className="btn" type="submit">
          {loading ? "Loading..." : "Login"}
        </button>
      </form>
    </div>
  );
};

export default Login;
