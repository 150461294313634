import React, { useState, useEffect } from "react";
import "./macn.scss";
import DeletionConfirmationModal from "../../../components/modal/DeleteConfirmationModal";
import AddMACNinfoPanel from "../../../components/sidebar/AddMACNinfoPanel";
import EditMACNinfoPanel from "../../../components/sidebar/EditMACNinfoPanel";
import { useDeleteMACN, useGetMACN } from "../../api/apiClient";
import { useNavigate } from "react-router-dom";
const MACN = () => {
  const getMACN = useGetMACN();
  const deleteMACN = useDeleteMACN();
  const navigate = useNavigate();
  const [deleteModalVisibility, setDeleteModalVisibility] = useState(false);
  const [MACNPanelVisibility, setMACNEditPanelVisibility] = useState(false);
  const [addMACNPanelVisibility, setAddMACNPanelVisibility] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [content, setContent] = useState({});
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [editItemId, setEditItemId] = useState(null);

  // console.log("content ", content)

  const handleDeleteBoardOfDirector = async () => {
    // run function
    // console.log("Delete item with ID:", deleteItemId);
    await deleteMACN(deleteItemId)
      .then((response) => {
        console.log(response.data);
        window.location.reload();
      })
      .catch((error) => {
        if (error.message === "Network Error") {
          alert("Network error! please check your internet connection");
        } else if (error.response.data.error === "jwt expired") {
          alert("Login Expired please login again");
          navigate("/");
        }
      });

    // Close modal
    setDeleteModalVisibility(false);
  };

  useEffect(() => {
    getMACN()
      .then((response) => {
        setContent(response.data.data);
        setIsLoading(false);
      })
      .catch((error) => {
        if (error.message === "Network Error") {
          alert("Network error! please check your internet connection");
        } else {
          console.log(error);
        }
      });
  }, []);

  return (
    <>
      <DeletionConfirmationModal
        visibility={deleteModalVisibility}
        setVisibility={setDeleteModalVisibility}
        contentType={"MACN information"}
        deleteFunction={handleDeleteBoardOfDirector}
      />

      {addMACNPanelVisibility && (
        <AddMACNinfoPanel
          visibility={addMACNPanelVisibility}
          setVisibility={setAddMACNPanelVisibility}
        />
      )}
      {MACNPanelVisibility && (
        <EditMACNinfoPanel
          editItemId={editItemId}
          visibility={MACNPanelVisibility}
          setVisibility={setMACNEditPanelVisibility}
        />
      )}
      {isLoading ? (
        <p>Loading...</p>
      ) : (
        <div className="innerPage ">
          <p
            className="add__board"
            onClick={() => setAddMACNPanelVisibility(true)}
          >
            Add MACN +
          </p>
          <div className="macn__container">
            {/* {[...Array(13)].map((each, index) => ( */}
            {content.map(({ title, text, createdAt, updatedAt, _id }) => (
              // <>
              <div key={_id}>
                <div className="created">
                  <p>Created at-{createdAt}</p>
                  <p>Edited at {updatedAt}</p>
                </div>
                <h3>
                  {title.length <= 30 ? title : title.substring(0, 50) + "..."}
                </h3>
                <div className="modify">
                  <button
                    className="edit-btn"
                    onClick={() => {
                      setEditItemId(_id);
                      setMACNEditPanelVisibility(true);
                    }}
                  >
                    Edit
                  </button>
                  <button
                    className="delete-btn"
                    onClick={() => {
                      setDeleteItemId(_id);
                      setDeleteModalVisibility(true);
                    }}
                  >
                    Delete
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default MACN;
