import { IoMdCloseCircleOutline } from "react-icons/io";
import styles from "../../styles/editBODInfoPanel.module.scss";
import { useState } from "react";
import {
  useGetVisionMision,
  usePutVisionMision,
} from "../../all-pages/api/apiClient";
import { useEffect } from "react";

const EditVisionMission = ({ visibility, setVisibility }) => {
  const getVisionMission = useGetVisionMision();
  const putVisionMission = usePutVisionMision();
  const [content, setContent] = useState({
    vision: "",
    mission: "",
    image: "",
  });
  const [updatedContent, setUpdatedContent] = useState({
    vision: "",
    mission: "",
    image: "",
  });
  const [isEditClick, setIsEditClick] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getVisionMission()
      .then((response) => {
        setContent(response.data.data);
        setUpdatedContent({
          vision: response.data.data.vision,
          mission: response.data.data.mission,
          image: response.data.data.image,
        });
        setImageFile(response.data.data.image);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [isEditClick]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setUpdatedContent((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  };

  // State to check if image is chosen
  const [isImageChosen, setIsImageChosen] = useState(false);

  //   State for image file
  const [imageFile, setImageFile] = useState();

  //   Error messages states
  const [imageErrorMsg, setImageErrorMsg] = useState(false);
  const [visionErrorMsg, setVisionErrorMsg] = useState(false);
  const [missionErrorMsg, setMissionErrorMsg] = useState(false);

  const handleImageChange = (e) => {
    // Log image file chosen
    // console.log(e.target.files[0]);
    // console.log(updatedContent);

    // Set the image url
    if (e.target.files[0]) {
      // Create object url
      const imgURL = URL.createObjectURL(e.target.files[0]);

      //   Set image file
      setImageFile(imgURL);
      //   Update boolean value for image chosen
      setIsImageChosen(true);
      // set updated image
      setUpdatedContent((prevValue) => {
        return {
          ...prevValue,
          ["image"]: e.target.files[0],
        };
      });
      //   Close image error message
      setImageErrorMsg(false);
    }
    // If no file was selected
    else {
      setImageFile(undefined);
      //   Update boolean value for image chosen
      setIsImageChosen(false);
      //   SHow image error message
      setImageErrorMsg(true);
    }
  };

  async function validateFormFields(e) {
    // Prevent default behaviour
    e.preventDefault();

    // If name or description or image is unavailable
    if (
      !content.vision ||
      !content.mission ||
      !imageFile ||
      !updatedContent.vision ||
      !updatedContent.mission ||
      !updatedContent
    ) {
      !content.vision ? setVisionErrorMsg(true) : setVisionErrorMsg(false);
      !content.mission ? setMissionErrorMsg(true) : setMissionErrorMsg(false);
      !imageFile ? setImageErrorMsg(true) : setImageErrorMsg(false);
      !updatedContent.vision
        ? setVisionErrorMsg(true)
        : setVisionErrorMsg(false);
      !updatedContent.mission
        ? setMissionErrorMsg(true)
        : setMissionErrorMsg(false);

      return;
    }

    // Else...
    // Run function to update info
    setIsLoading(true);
    console.log(updatedContent);
    // Clsoe panel
    await putVisionMission(updatedContent)
      .then((response) => {
        console.log("response: ", response.data);
        window.location.reload();
      })
      .catch((error) => {
        if (error.message === "Network Error") {
          alert("Network error! please check your internet connection");
        } else if (error.response.data.error === "jwt expired") {
          alert(
            "Failed to update content: Login Expired please try login again"
          );
        } else {
          console.log(error);
        }
      })
      .finally((error) => {
        // setLoading(false);
      });
    setVisibility(false);
  }

  return (
    <div className={styles.rightSidebarContainer}>
      <div
        className={styles.overlay}
        onClick={() => setVisibility(false)}
      ></div>
      <div
        className={`${styles.editBoardOfDirectorPanelContainer} ${styles.container}`}
      >
        <div className={styles.topArea}>
          <div className={styles.topArea__lhs}>
            <h4>Edit Vision and Mission Information</h4>
          </div>
          <div className={styles.topArea__rhs}>
            <button onClick={() => setVisibility(false)}>
              <IoMdCloseCircleOutline /> Close
            </button>
          </div>
        </div>
        <form className={styles.infoArea} onSubmit={validateFormFields}>
          <div className={styles.fieldControl}>
            <label htmlFor="name">Vision</label>
            <input
              type="text"
              name="vision"
              placeholder="Enter Vsion"
              value={updatedContent.vision}
              onChange={handleChange}
            />
            {visionErrorMsg && (
              <span className={styles.errorMsg}>Please enter Vision</span>
            )}
          </div>
          <div className={styles.fieldControl}>
            <label htmlFor="name">Mission</label>
            <input
              type="text"
              name="mission"
              placeholder="Enter Mission"
              value={updatedContent.mission}
              onChange={handleChange}
            />
            {missionErrorMsg && (
              <span className={styles.errorMsg}>Please enter Mision</span>
            )}
          </div>
          <div className={styles.fieldControl}>
            <div className={styles.imageControl}>
              <div className={styles.imageChosen}>
                <img
                  src={imageFile || content.image}
                  alt="visionMissionImage"
                />
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleImageChange}
                />
              </div>
            </div>
            {imageErrorMsg && (
              <span className={styles.errorMsg}>Please choose an image</span>
            )}
          </div>
          <button type="submit">
            {isLoading
              ? "Please wait, content uploading..."
              : "Update information"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default EditVisionMission;
