import { useEffect, useState } from "react";
import Information from "../../components/Information";
import "./home.scss";
import { Link, NavLink, useNavigate } from "react-router-dom";
import Navbar from "../../components/navbar/Navbar";
import { useAuth, useDeleteUser, useUser } from "../api/apiClient";
import DeletionConfirmationModal from "../../components/modal/DeleteConfirmationModal";

const Home = () => {
  const navigate = useNavigate();
  const getUsers = useUser();
  const authUser = useAuth();
  const deleteUsers = useDeleteUser();
  const [isLoading, setIsLoading] = useState(true);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [users, setUsers] = useState([
    {
      firstName: "",
      lastName: "",
      userName: "",
    },
  ]);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [deleteModalVisibility, setDeleteModalVisibility] = useState(false);

  const handleLogout = async () => {
    localStorage.clear();
    navigate("/logout");
  };

  useEffect(() => {
    authUser()
      .then((response) => {
        console.log(response.data.data);
        if (response.data.data.role == "superAdmin") {
          setIsSuperAdmin(true);
        }
        // console.log("Not super");

        setIsLoading(false);
      })
      .catch((error) => {
        if (error.message === "Network Error") {
          alert("Network error! please check your internet connection");
        } else {
          console.log(error);
        }
      });
  }, []);

  useEffect(() => {
    getUsers()
      .then((response) => {
        console.log(response.data.data);
        setUsers(response.data.data);
      })
      .catch((error) => {
        if (error.message === "Network Error") {
          alert("Network error! please check your internet connection");
        } else {
          console.log(error);
        }
      });
  }, [isSuperAdmin]);

  const handleDeleteUser = async () => {
    // run function
    console.log("Delete item with ID:", deleteItemId);
    await deleteUsers(deleteItemId)
      .then((response) => {
        console.log(response.data);
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });

    // Close modal
    setDeleteModalVisibility(false);
  };

  return (
    <>
      <DeletionConfirmationModal
        visibility={deleteModalVisibility}
        setVisibility={setDeleteModalVisibility}
        contentType={"Delete user information"}
        deleteFunction={handleDeleteUser}
      />

      {isLoading ? (
        <p>Loading...</p>
      ) : (
        <div className="home__container">
          <div>
            {isSuperAdmin ? (
              <div className="user__container">
                <NavLink to="register" className="add__board">
                  Register User{" + "}
                </NavLink>
                {users.map(({ firstName, lastName, userName, _id }) => (
                  // <>
                  <div key={_id}>
                    <div className="name">
                      <p>
                        First Name:{"  "}
                        {firstName}
                      </p>
                      <p>
                        Last Name:{"  "} {lastName}
                      </p>
                    </div>
                    <h3>{userName}</h3>
                    <div className="modify">
                      <button
                        className="delete-btn"
                        onClick={() => {
                          setDeleteItemId(_id);
                          setDeleteModalVisibility(true);
                        }}
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <p>
                <a className="loginSuper" onClick={() => handleLogout()}>
                  Log in
                </a>
                as super admin to register and view users.
              </p>
            )}
          </div>
          <div className="home__info-container">
            {/* <Information
              className=""
              upText="Conversion"
              downText="past 7 days"
              num="229"
            />
            <Information
              className=""
              upText="Conversion rate"
              downText="7 days average"
              num="0.9"
              icon="%"
            />
            <Information
              className=""
              upText="Session"
              downText="past 7 days"
              num="26.9"
              icon="k"
            />
            <Information
              className=""
              upText="Engagement"
              downText="past 7 days"
              num="80.3"
              icon="%"
            /> */}
          </div>
        </div>
      )}
    </>
  );
};

export default Home;
