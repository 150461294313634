import axios, { AxiosResponse } from "axios";
import { ApiRoutes } from "./apiRoutes";
import MemoryKeys from "../../components/memoryKeys";

export const API = axios.create({
  baseURL: ApiRoutes.BASE_URL_TEST,
});
const headers = {
  "content-Type": "multipart/form-data",
  Authorization: "Bearer " + localStorage.getItem(MemoryKeys.UserToken),
};
const headersNoCOntent = {
  Authorization: "Bearer " + localStorage.getItem(MemoryKeys.UserToken),
};

/**
 * The API hook to generate token
 * @returns Function to generate token
 */
export function useGenerateToken() {
  /**
   * Generate Token for user
   * @returns The response for the API request
   */
  async function generateToken() {
    // Generate token
    let response = await API.get(ApiRoutes.GenerateToken);

    // Return response
    return response;
  }

  // Return function to generate token
  return generateToken;
}

/**
 * The API hook to login
 * @returns Function to login
 */
export function useLogin() {
  /**
   * Login user
   * @returns The response for the API request
   */
  async function loginUser(data) {
    // Login user
    let response = await API.post(ApiRoutes.LoginUser, data);

    // Return response
    return response;
  }

  // Return function to login user
  return loginUser;
}

export function useAuth() {
  async function authUser() {
    let response = await API.get(ApiRoutes.auth, {
      headers: headers,
    });
    return response;
  }

  // Return function to login user
  return authUser;
}

export function useUser() {
  async function getUsers() {
    let response = await API.get(ApiRoutes.user, {
      headers: headers,
    });
    return response;
  }

  // Return function to login user
  return getUsers;
}

/**
 * The API hook to logout
 * @returns Function to logout
 */
export function useLogout() {
  /**
   * Logout user
   * @returns The response for the API request
   */
  async function logoutUser() {
    // Logout user
    let response = await API.post(ApiRoutes.Logoutser, {
      headers: headers,
    });

    // Return response
    return response;
  }

  // Return function to logout user
  return logoutUser;
}

/**
 * The API hook to register
 * @returns Function to register
 */
export function useRegister() {
  /**
   * Register user
   * @returns The response for the API request
   */
  async function registerUser(data) {
    // Register user
    let response = await API.post(ApiRoutes.RegisterUser, data);

    // Return response
    return response;
  }

  // Return function to register user
  return registerUser;
}

/**
 * The API hook to add board of directors
 * @returns Function to add board of directors
 */
export function useBoardOfDirectors() {
  /**
   * Register user
   * @returns The response for the API request
   */
  async function addBoardOfDirector(data) {
    // Register user
    let response = await API.post(ApiRoutes.BoardOfDirector, data, {
      headers: headers,
    });

    // Return response
    return response;
  }
  // Return function to add BOD
  return addBoardOfDirector;
}

export function usePutDOB() {
  /**
   * @returns The response for the API request
   */
  async function putDOB(updatedContent, documentId) {
    // Get BOD user data
    let response = await API.put(
      `${ApiRoutes.BoardOfDirector}\\${documentId}`,
      updatedContent,
      {
        headers: headers,
      }
    );

    // Return response
    return response;
  }

  return putDOB;
}

export function useDeleteBOD() {
  /**
   * @returns The response for the API request
   */
  async function deleteBOD(documentId) {
    // Get BOD user data
    let response = await API.delete(
      `${ApiRoutes.BoardOfDirector}\\${documentId}`,
      {
        headers: headers,
      }
    );

    // Return response
    return response;
  }

  return deleteBOD;
}
export function useGetBoardOfDirectorsById() {
  /**
   * Register user
   * @returns The response for the API request
   */
  async function getBODDataId(documentId) {
    // Get BOD user data
    let response = await API.get(
      `${ApiRoutes.BoardOfDirector}\\${documentId}`,
      {
        headers: headers,
      }
    );

    // Return response
    return response;
  }

  return getBODDataId;
}
export function useGetBoardOfDirectors() {
  /**
   * Register user
   * @returns The response for the API request
   */
  async function getBODData() {
    // Get BOD user data
    let response = await API.get(ApiRoutes.BoardOfDirector, {
      headers: headers,
    });

    // Return response
    return response;
  }

  return getBODData;
}
/******DOB Endpoint ends here *******/

/******Key Staff Endpoint Starts here *******/

export function useKeyStaffs() {
  /**
   * @returns The response for the API request
   */
  async function addKeyStaff(data) {
    // Register user
    let response = await API.post(ApiRoutes.KeyStaffs, data, {
      headers: headers,
    });

    // Return response
    return response;
  }
  // Return function to add BOD
  return addKeyStaff;
}
export function useGetKeyStaff() {
  /**
   * Register user
   * @returns The response for the API request
   */
  async function getKeyStaff() {
    // Get BOD user data
    let response = await API.get(ApiRoutes.KeyStaffs, {
      headers: headers,
    });

    // Return response
    return response;
  }

  return getKeyStaff;
}
export function usePutKeyStaff() {
  /**
   * @returns The response for the API request
   */
  async function putKeyStaff(updatedContent, documentId) {
    // Get BOD user data
    let response = await API.put(
      `${ApiRoutes.KeyStaffs}\\${documentId}`,
      updatedContent,
      {
        headers: headers,
      }
    );

    // Return response
    return response;
  }

  return putKeyStaff;
}

export function useDeleteKeyStaff() {
  /**
   * @returns The response for the API request
   */
  async function deleteKeyStaff(documentId) {
    // Get BOD user data
    let response = await API.delete(`${ApiRoutes.KeyStaffs}\\${documentId}`, {
      headers: headers,
    });

    // Return response
    return response;
  }

  return deleteKeyStaff;
}
export function useGetKeyStaffById() {
  /**
   * Register user
   * @returns The response for the API request
   */
  async function getKeyStaffDataId(documentId) {
    // Get BOD user data
    let response = await API.get(`${ApiRoutes.KeyStaffs}\\${documentId}`, {
      headers: headers,
    });

    // Return response
    return response;
  }

  return getKeyStaffDataId;
}
/******Key staff Endpoint ends here *******/

/**
 * The API hook to create macn information
 * @returns Function to create macn information
 */
export function useCreateMacnInformation() {
  /**
   * Register user
   * @returns The response for the API request
   */
  async function createMacnInformation(data) {
    // Register user
    let response = await API.post(ApiRoutes.CreateMacnInformation, data, {
      headers: headersNoCOntent,
    });

    // Return response
    return response;
  }
  // Return function to add macn information
  return createMacnInformation;
}

export function useGetMACN() {
  /**
   * Register user
   * @returns The response for the API request
   */
  async function getMACN() {
    // Get BOD user data
    let response = await API.get(ApiRoutes.CreateMacnInformation, {
      headers: headersNoCOntent,
    });

    // Return response
    return response;
  }

  return getMACN;
}
export function usePutMACN() {
  /**
   * @returns The response for the API request
   */
  async function putMACN(updatedContent, documentId) {
    // Get BOD user data
    let response = await API.put(
      `${ApiRoutes.CreateMacnInformation}\\${documentId}`,
      updatedContent,
      {
        headers: headersNoCOntent,
      }
    );

    // Return response
    return response;
  }

  return putMACN;
}
export function useDeleteMACN() {
  /**
   * @returns The response for the API request
   */
  async function deleteMACN(documentId) {
    // Get BOD user data
    let response = await API.delete(
      `${ApiRoutes.CreateMacnInformation}\\${documentId}`,
      {
        headers: headersNoCOntent,
      }
    );

    // Return response
    return response;
  }

  return deleteMACN;
}
export function useGetMACNById() {
  /**
   * Register user
   * @returns The response for the API request
   */
  async function getMACNDataId(documentId) {
    // Get BOD user data
    let response = await API.get(
      `${ApiRoutes.CreateMacnInformation}\\${documentId}`,
      {
        headers: headersNoCOntent,
      }
    );

    // Return response
    return response;
  }

  return getMACNDataId;
}

/******MACN Endpoint Ends here *******/

export function useGetVisionMision() {
  /**
   * @returns The response for the API request
   */
  async function useGetVisionMision() {
    // Get BOD user data
    let response = await API.get(ApiRoutes.VisionMission, {
      headers: headers,
    });

    // Return response
    return response;
  }

  return useGetVisionMision;
}

export function usePutVisionMision() {
  /**
   * @returns The response for the API request
   */
  async function useGetVisionMision(data) {
    // Get BOD user data
    let response = await API.put(ApiRoutes.VisionMission, data, {
      headers: headers,
    });

    // Return response
    return response;
  }

  return useGetVisionMision;
}

/******Resource Endpoint Starts here *******/

export function useResources() {
  /**
   * @returns The response for the API request
   */
  async function addResource(data) {
    // Register user
    let response = await API.post(ApiRoutes.Resources, data, {
      headers: headers,
    });
    // Return response
    return response;
  }
  // Return function to add BOD
  return addResource;
}
export function useGetRsource() {
  /**
   * Register user
   * @returns The response for the API request
   */
  async function getResources() {
    // Get BOD user data
    let response = await API.get(ApiRoutes.Resources, {
      headers: headers,
    });

    // Return response
    return response;
  }

  return getResources;
}
export function usePutResources() {
  /**
   * @returns The response for the API request
   */
  async function putResources(updatedContent, documentId) {
    // Get BOD user data
    let response = await API.put(
      `${ApiRoutes.Resources}\\${documentId}`,
      updatedContent,
      {
        headers: headers,
      }
    );

    // Return response
    return response;
  }

  return putResources;
}
export function useDeleteResource() {
  /**
   * @returns The response for the API request
   */
  async function deleteResource(documentId) {
    // Get BOD user data
    let response = await API.delete(`${ApiRoutes.Resources}\\${documentId}`, {
      headers: headers,
    });

    // Return response
    return response;
  }

  return deleteResource;
}
export function useGetResourcesById() {
  /**
   * Register user
   * @returns The response for the API request
   */
  async function getResourcesDataId(documentId) {
    // Get BOD user data
    let response = await API.get(`${ApiRoutes.Resources}\\${documentId}`, {
      headers: headers,
    });

    // Return response
    return response;
  }

  return getResourcesDataId;
}

/******Resources Endpoint ends here *******/

/**
 * The API hook to create BIF information
 * @returns Function to create BIF information
 */
export function useCreateBIFInformation() {
  /**
   * Register user
   * @returns The response for the API request
   */
  async function createBIFInformation(data) {
    // Register user
    let response = await API.post(ApiRoutes.BIF, data, {
      headers: headersNoCOntent,
    });

    // Return response
    return response;
  }
  // Return function to add macn information
  return createBIFInformation;
}

export function useGetBIF() {
  /**
   * Register user
   * @returns The response for the API request
   */
  async function getBIF() {
    // Get BOD user data
    let response = await API.get(ApiRoutes.BIF, {
      headers: headersNoCOntent,
    });

    // Return response
    return response;
  }

  return getBIF;
}
export function usePutBIF() {
  /**
   * @returns The response for the API request
   */
  async function putBIF(updatedContent, documentId) {
    // Get BOD user data
    let response = await API.put(
      `${ApiRoutes.BIF}\\${documentId}`,
      updatedContent,
      {
        headers: headersNoCOntent,
      }
    );

    // Return response
    return response;
  }

  return putBIF;
}
export function useDeleteBIF() {
  /**
   * @returns The response for the API request
   */
  async function deleteBIF(documentId) {
    // Get BOD user data
    let response = await API.delete(`${ApiRoutes.BIF}\\${documentId}`, {
      headers: headersNoCOntent,
    });

    // Return response
    return deleteBIF;
  }

  return deleteBIF;
}
export function useGetBIFById() {
  /**
   * Register user
   * @returns The response for the API request
   */
  async function getBIFById(documentId) {
    // Get BOD user data
    let response = await API.get(`${ApiRoutes.BIF}\\${documentId}`, {
      headers: headersNoCOntent,
    });

    // Return response
    return response;
  }

  return getBIFById;
}

/******BIF Endpoint Ends here *******/

/**
 * The API hook to create macn information
 * @returns Function to create CGRS information
 */
export function useCreateCGRSInformation() {
  /**
   * Register user
   * @returns The response for the API request
   */
  async function createCGRSInformation(data) {
    // Register user
    let response = await API.post(ApiRoutes.CGRS, data, {
      headers: headersNoCOntent,
    });

    // Return response
    return response;
  }
  // Return function to add macn information
  return createCGRSInformation;
}

export function useGetCGRS() {
  /**
   * Register user
   * @returns The response for the API request
   */
  async function getCGRS() {
    // Get BOD user data
    let response = await API.get(ApiRoutes.CGRS, {
      headers: headersNoCOntent,
    });

    // Return response
    return response;
  }

  return getCGRS;
}

export function usePutCGRS() {
  /**
   * @returns The response for the API request
   */
  async function putCGRS(updatedContent, documentId) {
    // Get BOD user data
    let response = await API.put(
      `${ApiRoutes.CGRS}\\${documentId}`,
      updatedContent,
      {
        headers: headersNoCOntent,
      }
    );

    // Return response
    return response;
  }

  return putCGRS;
}
export function useDeleteCGRS() {
  /**
   * @returns The response for the API request
   */
  async function deleteBIF(documentId) {
    // Get BOD user data
    let response = await API.delete(`${ApiRoutes.CGRS}\\${documentId}`, {
      headers: headersNoCOntent,
    });

    // Return response
    return response;
  }

  return deleteBIF;
}
export function useGetCGRSById() {
  /**
   * Register user
   * @returns The response for the API request
   */
  async function getCGRSById(documentId) {
    // Get BOD user data
    let response = await API.get(`${ApiRoutes.CGRS}\\${documentId}`, {
      headers: headersNoCOntent,
    });

    // Return response
    return response;
  }

  return getCGRSById;
}

/******CGRS Endpoint Ends here *******/

/**
 * The API hook to create macn information
 * @returns Function to create BAAC information
 */

export function useCreateBAACInformation() {
  /**
   * Register user
   * @returns The response for the API request
   */
  async function createBAACInformation(data) {
    // Register user
    let response = await API.post(ApiRoutes.BAAC, data, {
      headers: headersNoCOntent,
    });

    // Return response
    return response;
  }
  // Return function to add macn information
  return createBAACInformation;
}

export function useGetBAAC() {
  /**
   * Register user
   * @returns The response for the API request
   */
  async function getBAAC() {
    // Get BOD user data
    let response = await API.get(ApiRoutes.BAAC, {
      headers: headersNoCOntent,
    });

    // Return response
    return response;
  }

  return getBAAC;
}

export function usePutBAAC() {
  /**
   * @returns The response for the API request
   */
  async function putBAAC(updatedContent, documentId) {
    // Get BOD user data
    let response = await API.put(
      `${ApiRoutes.BAAC}\\${documentId}`,
      updatedContent,
      {
        headers: headersNoCOntent,
      }
    );

    // Return response
    return response;
  }

  return putBAAC;
}
export function useDeleteBAAC() {
  /**
   * @returns The response for the API request
   */
  async function deleteBAAC(documentId) {
    // Get BOD user data
    let response = await API.delete(`${ApiRoutes.BAAC}\\${documentId}`, {
      headers: headersNoCOntent,
    });

    // Return response
    return response;
  }

  return deleteBAAC;
}
export function useGetBAACById() {
  /**
   * Register user
   * @returns The response for the API request
   */
  async function getBAACById(documentId) {
    // Get BOD user data
    let response = await API.get(`${ApiRoutes.BAAC}\\${documentId}`, {
      headers: headersNoCOntent,
    });

    // Return response
    return response;
  }

  return getBAACById;
}

/**
 * The API hook to create BLog information
 * @returns Function to create Blog information
 */

export function useCreateBlogInformation() {
  /**
   * Register user
   * @returns The response for the API request
   */
  async function createBlogInformation(data) {
    // Register user
    let response = await API.post(ApiRoutes.Blog, data, {
      headers: headers,
    });

    // Return response
    return response;
  }
  // Return function to add macn information
  return createBlogInformation;
}

export function useGetBlog() {
  /**
   * Register user
   * @returns The response for the API request
   */
  async function getBlog() {
    // Get BOD user data
    let response = await API.get(ApiRoutes.Blog, {
      headers: headersNoCOntent,
    });

    // Return response
    return response;
  }

  return getBlog;
}

export function usePutBlog() {
  /**
   * @returns The response for the API request
   */
  async function putBlog(updatedContent, documentId) {
    // Get BOD user data
    let response = await API.put(
      `${ApiRoutes.Blog}\\${documentId}`,
      updatedContent,
      {
        headers: headers,
      }
    );

    // Return response
    return response;
  }

  return putBlog;
}
export function useDeleteBlog() {
  /**
   * @returns The response for the API request
   */
  async function deleteBIF(documentId) {
    // Get BOD user data
    let response = await API.delete(`${ApiRoutes.Blog}\\${documentId}`, {
      headers: headersNoCOntent,
    });

    // Return response
    return response;
  }

  return deleteBIF;
}
export function useGetBlogById() {
  /**
   * Register user
   * @returns The response for the API request
   */
  async function getBlogById(documentId) {
    // Get BOD user data
    let response = await API.get(`${ApiRoutes.Blog}\\${documentId}`, {
      headers: headersNoCOntent,
    });

    // Return response
    return response;
  }

  return getBlogById;
}

// Endpoinnt to fetch image URL
export function useGetURL() {
  async function getURL(link) {
    // Get Link  data
    let response = await API.post(ApiRoutes.GetURL, link, {
      headers: headers,
    });

    // Return response
    return response;
  }

  return getURL;
}

export function useDeleteUser() {
  /**
   * @returns The response for the API request
   */
  async function deleteUser(documentId) {
    // Get BOD user data
    let response = await API.delete(`${ApiRoutes.user}\\${documentId}`, {
      headers: headersNoCOntent,
    });

    // Return response
    return response;
  }

  return deleteUser;
}
