import { IoMdCloseCircleOutline, IoMdAddCircleOutline } from "react-icons/io";
import styles from "../../styles/editBODInfoPanel.module.scss";
import React, { useState, useRef, useMemo, useEffect } from "react";
import "jodit";
import "jodit/build/jodit.min.css";
import JoditEditor from "jodit-react";
import {
  useGetBAACById,
  useGetURL,
  usePutBAAC,
} from "../../all-pages/api/apiClient";

import { useNavigate } from "react-router-dom";

const EditBAACinfoPanel = ({ editItemId, visibility, setVisibility }) => {
  const navigate = useNavigate();
  const putBAAC = usePutBAAC();
  const editor = useRef(null);
  const getBAACByID = useGetBAACById();
  const getURL = useGetURL();
  const config = useMemo(
    () => ({
      readonly: false, // all options from https://xdsoft.net/jodit/docs/,
      placeholder: "Start typings...",
    })
    // [placeholder]
  );

  function removeTagsFromString(inputString) {
    const parser = new DOMParser();
    const decodedString = parser.parseFromString(inputString, "text/html")
      .documentElement.textContent;
    return decodedString;
  }

  // State to check if image is chosen
  const [isImageChosen, setIsImageChosen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [fetchedImage, setFetchedImage] = useState(false);

  //   State for image file
  const [image, setImage] = useState();
  const [imageFileLink, setImageFileLink] = useState();

  // const [addDetails, setaddDetails] = useState({
  //   title: "",
  // });
  const [content, setNewContent] = useState("");
  const [title, setNewTitle] = useState("");
  //   Error messages states
  const [imageErrorMsg, setImageErrorMsg] = useState(false);
  const [nameErrorMsg, setNameErrorMsg] = useState(false);
  const [contentErrorMsg, setContentErrorMsg] = useState(false);

  useEffect(() => {
    getBAACByID(editItemId)
      .then((response) => {
        console.log(response.data);
        setNewTitle(response.data.data.title);
        setNewContent(removeTagsFromString(response.data.data.text));
        setIsLoading(false);
      })
      .catch((error) => {
        if (error.message === "Network Error") {
          alert("Network error! please check your internet connection");
        } else if (error.response.data.error === "jwt expired") {
          alert("Login Expired please login again");
          navigate("/login");
        }
      });
  }, []);

  const handleImageChange = async (e) => {
    // Set the image url
    if (e.target.files[0]) {
      // Create object url
      const imgURL = URL.createObjectURL(e.target.files[0]);
      setImageFileLink(imgURL);

      //   Set image file
      setImage(e.target.files[0]);
      //   Update boolean value for image chosen
      setIsImageChosen(true);
      //   Close image error message
      setImageErrorMsg(false);
      const dataToSend = {
        image: e.target.files[0],
      };

      await getURL(dataToSend)
        .then((response) => {
          console.log("response: image link generated");
          setFetchedImage(response.data.data.url);
          // window.location.reload();
        })
        .catch((error) => {
          console.log("error: ", error);
          setFetchedImage("There was an error fetching image");
          if (error.message === "Network Error") {
            alert("Network error! please check your internet connection");
          } else if (error.response.data.error === "jwt expired") {
            alert("Login Expired please login again");
            navigate("/login");
          }
        })
        .finally((error) => {
          // setLoading(false);
        });
    }
    // If no file was selected
    else {
      setImage(undefined);
      //   Update boolean value for image chosen
      setIsImageChosen(false);
      //   SHow image error message
      setImageErrorMsg(true);
    }
  };

  async function validateFormFields(e) {
    // Prevent default behaviour
    setIsLoading(true);
    e.preventDefault();

    // If title or content or image is unavailable
    if (!title || !content) {
      !title ? setNameErrorMsg(true) : setNameErrorMsg(false);
      !content ? setContentErrorMsg(true) : setContentErrorMsg(false);
      return;
    }
    const dataToSend = {
      title,
      text: content,
    };

    console.log(dataToSend);
    // Run function to add info
    await putBAAC(dataToSend, editItemId)
      .then((response) => {
        console.log("response: ", response.data);
        window.location.reload();
      })
      .catch((error) => {
        if (error.message === "Network Error") {
          alert("Network error! please check your internet connection");
        } else if (error.response.data.error === "jwt expired") {
          alert("Failed to update content: Login Expired please login again");
          navigate("/login");
        } else {
          console.log(error);
        }
      })
      .finally((error) => {
        // setLoading(false);
      });

    // Clsoe panel
    setVisibility(false);
    setIsLoading(false);
  }
  return (
    <div className={styles.rightSidebarContainer}>
      <div
        className={styles.overlay}
        onClick={() => setVisibility(false)}
      ></div>
      {isLoading ? (
        <p>Fetching content...</p>
      ) : (
        <div
          className={`${styles.editBoardOfDirectorPanelContainer} ${styles.container}`}
        >
          <div className={styles.topArea}>
            <div className={styles.topArea__lhs}>
              <h4>Edit BAAC Information</h4>
            </div>
            <div className={styles.topArea__rhs}>
              <button onClick={() => setVisibility(false)}>
                <IoMdCloseCircleOutline /> Close
              </button>
            </div>
          </div>
          <form className={styles.infoArea} onSubmit={validateFormFields}>
            <div className={styles.fieldControl}>
              <label htmlFor="name">Title</label>
              <input
                type="text"
                name="title"
                placeholder="Enter Title"
                value={title}
                onChange={(e) => setNewTitle(e.target.value)}
              />
              {nameErrorMsg && (
                <span className={styles.errorMsg}>Please enter title</span>
              )}
            </div>
            <div className={styles.fieldControl}>
              <label htmlFor="position">
                {fetchedImage &&
                  "Image Link generatd copy and paste to the text editor"}
              </label>
              {imageFileLink ? (
                <input
                  type="text"
                  name="bookLink"
                  placeholder={"ddh"}
                  value={fetchedImage ? fetchedImage : "Generating URL..."}
                  onChange={(e) => setFetchedImage(fetchedImage)}
                />
              ) : (
                <p>Select an image to view url</p>
              )}
            </div>
            <div className={styles.fieldControl}>
              <div className={styles.imageControl}>
                {isImageChosen ? (
                  <div className={styles.imageChosen}>
                    <img src={imageFileLink} alt="boardDorctormmage" />
                    <input
                      type="file"
                      name="image"
                      accept="image/*"
                      onChange={(e) => handleImageChange(e)}
                    />
                  </div>
                ) : (
                  <span>
                    <IoMdAddCircleOutline /> Choose image
                    <input
                      type="file"
                      accept="image/*"
                      onChange={(e) => handleImageChange(e)}
                    />
                  </span>
                )}
              </div>
              {imageErrorMsg && (
                <span className={styles.errorMsg}>Please choose an image</span>
              )}
            </div>

            {/* <div className={styles.fieldControl}>{TextEditor()}</div> */}
            <div className={styles.fieldControl}>
              <JoditEditor
                // ref={editor}
                value={content}
                // config={config}
                tabIndex={2} // tabIndex of textarea
                onBlur={(content) => setNewContent(content)} // preferred to use only this option to update the content for performance reasons
                // onChange={(newContent) => {}}
                // onChange={(e) => setContent(e.target.value)}
              />
              {contentErrorMsg && (
                <span className={styles.errorMsg}>Please enter content</span>
              )}
            </div>
            <button type="submit">
              {isLoading ? "Adding information..." : "Add information"}
            </button>
          </form>
        </div>
      )}
    </div>
  );
};

export default EditBAACinfoPanel;
