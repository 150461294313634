import { BrowserRouter, Route, Routes } from "react-router-dom";
import Sidebar from "./components/navbar/Navbar";
import { Login, Portal, Home, Resources, Blog } from "./all-pages";
import "./App.scss";
import {
  MACN,
  CoperateGovenance,
  BusinessAction,
  BusinessInnovation,
} from "./all-pages/projects";

import {
  VisionMission,
  Culture,
  BoardOfDirectors,
  KeyStaff,
} from "./all-pages/about-us";
import Register from "./all-pages/register/Register";
import Logout from "./all-pages/logout/Logout";

const App = () => {
  return (
    <div>
      <BrowserRouter basename="/">
        <div className="admin__page-wrapper">
          <div className="admin__page-left">
            <Sidebar />
          </div>
          <div className="admin__page-right">
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/" element={<Login />} />
              <Route path="/admin/register" element={<Register />} />
              <Route path="/logout" element={<Logout />} />
              <Route path="/admin" element={<Home />} />

              {/* Project Menu Pages */}
              <Route path="/admin/MACN" element={<MACN />} />
              <Route
                path="/admin/coperate-govenance"
                element={<CoperateGovenance />}
              />
              <Route
                path="/admin/busness-action"
                element={<BusinessAction />}
              />
              <Route
                path="/admin/business-innovation"
                element={<BusinessInnovation />}
              />

              {/* About us Menu Pages */}
              <Route path="/admin/vision-mission" element={<VisionMission />} />
              <Route path="/admin/culture" element={<Culture />} />
              <Route
                path="/admin/board-of-directors"
                element={<BoardOfDirectors />}
              />
              <Route path="/admin/key-staff" element={<KeyStaff />} />

              <Route path="/admin/portal" element={<Portal />} />
              <Route path="/admin/resources" element={<Resources />} />
              <Route path="/admin/blog" element={<Blog />} />
              <Route path="*" element="404" />
            </Routes>
          </div>
        </div>
      </BrowserRouter>
    </div>
  );
};

export default App;
