import React, { useEffect, useState } from "react";
import Logo from "../../assets/aaj.png";
import "./sidebar.scss";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { aboutUs, projectLink } from "../../assets/data";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { useAuth, useLogout } from "../../all-pages/api/apiClient";

const Navbar = () => {
  const { setToken } = useAuth();
  const logout = useLogout();
  const navigate = useNavigate();

  const [projectsDropdownOpen, setProjectsDropdownOpen] = useState(false);
  const [aboutUsDropdownOpen, setAboutUsDropdownOpen] = useState(false);

  const location = useLocation();

  const toggleProjectDropdown = () => {
    setProjectsDropdownOpen(!projectsDropdownOpen);
  };

  const toggleAboutUsDropdown = () => {
    setAboutUsDropdownOpen(!aboutUsDropdownOpen);
  };

  const handleLogout = async () => {
    localStorage.clear();
    navigate("/logout");
  };

  return (
    <div
      className={`sidebar ${
        location.pathname === "/login" ||
        location.pathname === "/admin/register" ||
        location.pathname === "/"
          ? "none"
          : ""
      }`}
    >
      <div className="logo__container">
        <img src={Logo} alt="integrityLogo" />
      </div>
      <div className="sidebar__menu">
        <ul className="menu__wrap">
          <li>
            <NavLink
              className={({ isActive }) => (isActive ? "active-nav" : "black")}
              to="/admin"
            >
              Home
            </NavLink>
          </li>
          {/* <li>
            <button
              onClick={toggleProjectDropdown}
              className="bold dropdown__title"
            >
              Project
              {projectsDropdownOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}
            </button>
            {projectsDropdownOpen && (
              <ul className="dropdown-menu">
                {projectLink.map(({ id, text, path }) => (
                  <NavLink
                    key={id}
                    to={`/admin/${path}`}
                    className={({ isActive }) =>
                      isActive ? "active-nav" : "black"
                    }
                  >
                    <li>{text}</li>
                  </NavLink>
                ))}
              </ul>
            )}
          </li> */}
          {/* <li>
            <button
              onClick={toggleAboutUsDropdown}
              className="bold dropdown__title"
            >
              About us
              {aboutUsDropdownOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}
            </button>
            {aboutUsDropdownOpen && (
              <ul className="dropdown-menu">
                {aboutUs.map(({ id, text, path }) => (
                  <NavLink
                    className={({ isActive }) =>
                      isActive ? "active-nav" : "black"
                    }
                    key={id}
                    to={`/admin/${path}`}
                  >
                    <li>{text}</li>
                  </NavLink>
                ))}
              </ul>
            )}
          </li> */}
          {/* <li className="bold">
            <NavLink
              className={({ isActive }) => (isActive ? "active-nav" : "black")}
              to="/admin/resources"
            >
              Resources
            </NavLink>
          </li> */}
          <li className="bold">
            <NavLink
              className={({ isActive }) => (isActive ? "active-nav" : "black")}
              to="/admin/blog"
            >
              Blog
            </NavLink>
          </li>
        </ul>
      </div>
      <li className="logout" onClick={() => handleLogout()}>
        Log out
      </li>
    </div>
  );
};

export default Navbar;
