import { IoMdAddCircleOutline, IoMdCloseCircleOutline } from "react-icons/io";
import styles from "../../styles/editBODInfoPanel.module.scss";
import { useState } from "react";
import { useResources } from "../../all-pages/api/apiClient";

const AddResourceInfoPanel = ({ visibility, setVisibility }) => {
  const addResources = useResources();

  // State to check if image is chosen
  const [isImageChosen, setIsImageChosen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  //   State for image file
  const [imageFile, setImageFile] = useState();
  const [imageFileLink, setImageFileLink] = useState();

  const [addDetails, setaddDetails] = useState({
    title: "",
    bookLink: "",
  });

  //   Error messages states
  const [imageErrorMsg, setImageErrorMsg] = useState(false);
  const [nameErrorMsg, setNameErrorMsg] = useState(false);
  const [bookLinkErrorMsg, setbBookLinkErrorMsg] = useState(false);
  const [descriptionErrorMsg, setDescriptionErrorMsg] = useState(false);
  // const [addDetails, setAddDetails] = useState({});
  /**
   * Function to handle image change
   * @param {*} e is the sender of the event
   */
  const handleImageChange = (e) => {
    // Log image file chosen
    // console.log(e.target.files[0]);
    // console.log(imageFile);

    // Set the image url
    if (e.target.files[0]) {
      // Create object url
      const imgURL = URL.createObjectURL(e.target.files[0]);
      setImageFileLink(imgURL);

      //   Set image file
      setImageFile(e.target.files[0]);
      //   Update boolean value for image chosen
      setIsImageChosen(true);
      //   Close image error message
      setImageErrorMsg(false);
    }
    // If no file was selected
    else {
      setImageFile(undefined);
      //   Update boolean value for image chosen
      setIsImageChosen(false);
      //   SHow image error message
      setImageErrorMsg(true);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    setaddDetails((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  };

  /**
   * Function to validate form fields
   * @param {*} e is the sender of the event
   * @returns
   */
  async function validateFormFields(e) {
    // Prevent default behaviour
    setIsLoading(true);
    e.preventDefault();
    const dataToSend = {
      title: addDetails.title,
      bookLink: addDetails.bookLink,
      image: imageFile,
    };

    // If name or description or image is unavailable
    if (
      !dataToSend.title ||
      !dataToSend.bookLink ||
      !dataToSend.image ||
      !imageFile
    ) {
      !dataToSend.title ? setNameErrorMsg(true) : setNameErrorMsg(false);
      !dataToSend.bookLink
        ? setbBookLinkErrorMsg(true)
        : setbBookLinkErrorMsg(false);
      !dataToSend.image
        ? setDescriptionErrorMsg(true)
        : setDescriptionErrorMsg(false);
      !imageFile ? setImageErrorMsg(true) : setImageErrorMsg(false);
      return;
    }

    // Run function to add info
    await addResources(dataToSend)
      .then((response) => {
        console.log("response: ", response.data);
        window.location.reload();
      })
      .catch((error) => {
        if (error.message === "Network Error") {
          alert("Network error! please check your internet connection");
        } else if (error.response.data.error === "jwt expired") {
          alert(
            "Failed to update content: Login Expired please try login again"
          );
        } else {
          console.log(error);
        }
      })
      .finally((error) => {
        // setLoading(false);
      });

    // Clsoe panel
    setVisibility(false);
    setIsLoading(false);
  }

  return (
    <div className={styles.rightSidebarContainer}>
      <div
        className={styles.overlay}
        onClick={() => setVisibility(false)}
      ></div>
      <div
        className={`${styles.editBoardOfDirectorPanelContainer} ${styles.container}`}
      >
        <div className={styles.topArea}>
          <div className={styles.topArea__lhs}>
            <h4>Add Resources Information</h4>
          </div>
          <div className={styles.topArea__rhs}>
            <button onClick={() => setVisibility(false)}>
              <IoMdCloseCircleOutline /> Close
            </button>
          </div>
        </div>
        <form className={styles.infoArea} onSubmit={validateFormFields}>
          <div className={styles.fieldControl}>
            <label htmlFor="name">Title</label>
            <input
              type="text"
              name="title"
              placeholder="Enter Title"
              value={addDetails.title}
              onChange={handleChange}
            />
            {nameErrorMsg && (
              <span className={styles.errorMsg}>Please enter title</span>
            )}
          </div>
          <div className={styles.fieldControl}>
            <label htmlFor="position">Link</label>
            <input
              type="text"
              name="bookLink"
              placeholder="https://www.cbinigeria.com/..."
              value={addDetails.bookLink}
              onChange={handleChange}
            />
            {bookLinkErrorMsg && (
              <span className={styles.errorMsg}>Please enter the link</span>
            )}
          </div>
          <div className={styles.fieldControl}>
            <div className={styles.imageControl}>
              {isImageChosen ? (
                <div className={styles.imageChosen}>
                  <img src={imageFileLink} alt="boardDorctormmage" />
                  <input
                    type="file"
                    name="image"
                    accept="image/*"
                    onChange={(e) => handleImageChange(e)}
                  />
                </div>
              ) : (
                <span>
                  <IoMdAddCircleOutline /> Choose image
                  <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => handleImageChange(e)}
                  />
                </span>
              )}
            </div>
            {imageErrorMsg && (
              <span className={styles.errorMsg}>Please choose an image</span>
            )}
          </div>
          <button type="submit">
            {isLoading ? "Adding information..." : "Add information"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default AddResourceInfoPanel;
