"use client";
import { useEffect, useState } from "react";
import "./preloader.css";

function CustomPreloader() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 10000);
  }, []);
  return loading ? (
    <div className="preloaderContainer">
      <span className="loader"></span>
    </div>
  ) : null;
}

export default CustomPreloader;
