/**
 * The API routes endpoints
 */
export class ApiRoutes {
  /**
   * The development base url for the application
   */
  static BASE_URL_DEV = "https://localhost:0000";

  /**
   * The production base url for the application
   */
  static BASE_URL_LIVE = "http://api.example.com";

  /**
   * The test base url for the application
   */
  static BASE_URL_TEST = "https://integrity-test.azurewebsites.net/";

  /**
   * The route to the GenerateToken endpoint
   */
  static GenerateToken = "api/v1/auth/generatetoken";

  /**
   * The route to the LoginUser endpoint
   */
  static LoginUser = "api/v1/auth/login";

  /**
   * The route to the Logioutser endpoint
   */
  static Logoutser = "api/v1/auth/logout";

  /**
   * The route to the RegisterUser endpoint
   */
  static RegisterUser = "api/v1/auth/";

  /**
   * The route for Blog endpoint
   */
  static Blog = "/api/v1/blog";

  /**
   * The route for superAdmin endpoint
   */
  static auth = "/api/v1/auth";

  static user = "/api/v1/users";

  /**
   * The route for GetURL endpoint
   */
  static GetURL = "/api/v1/get-url";

  /**
   * The route to the ResetPassword endpoint
   */
  static ResetPassword = (tokenRetrieved) =>
    `api/v1/auth/resetpassword/:${tokenRetrieved}`;
}
