import { useState, useEffect } from "react";
import DeletionConfirmationModal from "../../components/modal/DeleteConfirmationModal";
import AddResourceInfoPanel from "../../components/sidebar/AddResourceInfoPanel";
import EditResourcesInfoPanel from "../../components/sidebar/EditResourcesInfoPanel";
import { useDeleteResource, useGetRsource } from "../api/apiClient";
import "./resources.scss";

const Resources = () => {
  const getResources = useGetRsource();
  const deleteResurces = useDeleteResource();

  const [dataFetched, setDataFetched] = useState(false);
  const [deleteModalVisibility, setDeleteModalVisibility] = useState(false);
  const [editResourcesPanelVisibility, setEditResourcesPanelVisibility] =
    useState(false);
  const [addResourcesPanelVisibility, setAddResourcesPanelVisibility] =
    useState(false);
  const [resourcesData, setResourcesData] = useState([]);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [editItemId, setEditItemId] = useState(null);

  const handleDeleteBoardOfDirector = async () => {
    await deleteResurces(deleteItemId)
      .then((response) => {
        console.log(response);
        window.location.reload();
      })
      .catch((error) => {
        if (error.message === "Network Error") {
          alert("Network error! please check your internet connection");
        } else if (error.response.data.error === "jwt expired") {
          alert("Failed to update content: Login Expired please login again");
        }
      });

    // Close modal
    setDeleteModalVisibility(false);
  };

  useEffect(() => {
    getResources()
      .then((response) => {
        setResourcesData(response.data.data);
        setDataFetched(true);
      })
      .catch((error) => {
        if (error.message === "Network Error") {
          alert(
            "Network Error! please make sure you connected to the internet!"
          );
        } else {
          console.log(error);
        }
      });
  }, []);

  return (
    <>
      <DeletionConfirmationModal
        visibility={deleteModalVisibility}
        setVisibility={setDeleteModalVisibility}
        contentType={"Resources information"}
        deleteFunction={handleDeleteBoardOfDirector}
      />
      {addResourcesPanelVisibility && (
        <AddResourceInfoPanel
          visibility={addResourcesPanelVisibility}
          setVisibility={setAddResourcesPanelVisibility}
        />
      )}
      {editResourcesPanelVisibility && (
        <EditResourcesInfoPanel
          visibility={editResourcesPanelVisibility}
          setVisibility={setEditResourcesPanelVisibility}
          editItemId={editItemId}
        />
      )}

      <div className="innerPage resources__container">
        <p
          className="add__board"
          onClick={() => setAddResourcesPanelVisibility(true)}
        >
          Add book resources +
        </p>{" "}
        {dataFetched ? (
          <div className="resources__container-files">
            {resourcesData.map(({ title, bookLink, image, _id }) => (
              <div className="resources__container-file" key={_id}>
                <a
                  href={bookLink}
                  target="_blank"
                  rel="noreferrer"
                  style={{ textDecoration: "none" }}
                >
                  <div className="files___image">
                    <img src={image} alt="RESOURCES" />
                  </div>
                  <h4 className="resourceTitle">{title}</h4>
                </a>
                <div className="flex">
                  <button
                    className="edit-btn"
                    onClick={() => {
                      setEditItemId(_id);
                      setEditResourcesPanelVisibility(true);
                    }}
                  >
                    Edit
                  </button>
                  <button
                    className="delete-btn"
                    onClick={() => {
                      setDeleteItemId(_id);
                      setDeleteModalVisibility(true);
                    }}
                  >
                    Delete
                  </button>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <p>Fetching Resources data please wait...</p>
        )}
      </div>
    </>
  );
};

export default Resources;
