import { IoMdAddCircleOutline, IoMdCloseCircleOutline } from "react-icons/io";
import styles from "../../styles/editBODInfoPanel.module.scss";
import { useState } from "react";
import { useKeyStaffs } from "../../all-pages/api/apiClient";

const AddKeyStaffInfoPanel = ({ visibility, setVisibility }) => {
  const addKeyStaff = useKeyStaffs();

  // State to check if image is chosen
  const [isImageChosen, setIsImageChosen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  //   State for image file
  const [imageFile, setImageFile] = useState();
  const [imageFileLink, setImageFileLink] = useState();

  const [addDetails, setaddDetails] = useState({
    name: "",
    description: "",
    position: "",
  });

  //   Error messages states
  const [imageErrorMsg, setImageErrorMsg] = useState(false);
  const [nameErrorMsg, setNameErrorMsg] = useState(false);
  const [positionErrorMsg, setPositionErrorMsg] = useState(false);
  const [descriptionErrorMsg, setDescriptionErrorMsg] = useState(false);
  // const [addDetails, setAddDetails] = useState({});
  /**
   * Function to handle image change
   * @param {*} e is the sender of the event
   */
  const handleImageChange = (e) => {
    // Log image file chosen
    // console.log(e.target.files[0]);
    // console.log(imageFile);

    // Set the image url
    if (e.target.files[0]) {
      // Create object url
      const imgURL = URL.createObjectURL(e.target.files[0]);
      setImageFileLink(imgURL);

      //   Set image file
      setImageFile(e.target.files[0]);
      //   Update boolean value for image chosen
      setIsImageChosen(true);
      //   Close image error message
      setImageErrorMsg(false);
    }
    // If no file was selected
    else {
      setImageFile(undefined);
      //   Update boolean value for image chosen
      setIsImageChosen(false);
      //   SHow image error message
      setImageErrorMsg(true);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    setaddDetails((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  };

  /**
   * Function to validate form fields
   * @param {*} e is the sender of the event
   * @returns
   */
  async function validateFormFields(e) {
    // Prevent default behaviour
    setIsLoading(true);
    e.preventDefault();

    // If name or description or image is unavailable
    if (
      !addDetails.name ||
      !addDetails.position ||
      !addDetails.description ||
      !imageFile
    ) {
      !addDetails.name ? setNameErrorMsg(true) : setNameErrorMsg(false);
      !addDetails.position
        ? setPositionErrorMsg(true)
        : setPositionErrorMsg(false);
      !addDetails.description
        ? setDescriptionErrorMsg(true)
        : setDescriptionErrorMsg(false);
      !imageFile ? setImageErrorMsg(true) : setImageErrorMsg(false);
      return;
    }

    const dataToSend = {
      name: addDetails.name,
      text: addDetails.description,
      position: addDetails.position,
      image: imageFile,
    };

    console.log(addDetails);

    // Run function to add info
    await addKeyStaff(dataToSend)
      .then((response) => {
        console.log("response: ", response.data);
        window.location.reload();
      })
      .catch((error) => {
        if (error.response.data.error === "jwt expired") {
          alert("Faild to update content! Login Expired please login again");
        } else {
          if (error.message === "Network Error") {
            alert("Network error! please check your internet connection");
          } else if (error.response.data.error === "jwt expired") {
            alert(
              "Failed to update content: Login Expired please try login again"
            );
          } else {
            console.log(error);
          }
        }
      })
      .finally((error) => {
        // setLoading(false);
      });

    // Clsoe panel
    console.log(addDetails);
    setVisibility(false);
  }

  return (
    <div className={styles.rightSidebarContainer}>
      <div
        className={styles.overlay}
        onClick={() => setVisibility(false)}
      ></div>
      <div
        className={`${styles.editBoardOfDirectorPanelContainer} ${styles.container}`}
      >
        <div className={styles.topArea}>
          <div className={styles.topArea__lhs}>
            <h4>Add Key Staff Information</h4>
          </div>
          <div className={styles.topArea__rhs}>
            <button onClick={() => setVisibility(false)}>
              <IoMdCloseCircleOutline /> Close
            </button>
          </div>
        </div>
        <form className={styles.infoArea} onSubmit={validateFormFields}>
          <div className={styles.fieldControl}>
            <label htmlFor="name">Name</label>
            <input
              type="text"
              name="name"
              placeholder="Enter name"
              value={addDetails.name}
              onChange={handleChange}
              // onChange={(e) => {
              //   handleChange;
              //   // name.length > 0 ? setNameErrorMsg(false) : setNameErrorMsg(true);
              //   !e.target.value
              //     ? setNameErrorMsg(true)
              //     : setNameErrorMsg(false);
              // }}
            />
            {nameErrorMsg && (
              <span className={styles.errorMsg}>Please enter name</span>
            )}
          </div>
          <div className={styles.fieldControl}>
            <label htmlFor="position">Position</label>
            <input
              type="text"
              name="position"
              placeholder="Enter Position(eg CEO, Co-Founder..."
              value={addDetails.position}
              onChange={handleChange}
              // onChange={(e) => {
              //   handleChange;
              //   // name.length > 0 ? setNameErrorMsg(false) : setNameErrorMsg(true);
              //   !e.target.value
              //     ? setNameErrorMsg(true)
              //     : setNameErrorMsg(false);
              // }}
            />
            {positionErrorMsg && (
              <span className={styles.errorMsg}>Please enter the Position</span>
            )}
          </div>
          <div className={styles.fieldControl}>
            <label htmlFor="description">Description</label>
            <textarea
              type="text"
              name="description"
              placeholder="Enter description"
              value={addDetails.description}
              onChange={handleChange}
            />
            {descriptionErrorMsg && (
              <span className={styles.errorMsg}>Please enter description</span>
            )}
          </div>
          <div className={styles.fieldControl}>
            <div className={styles.imageControl}>
              {isImageChosen ? (
                <div className={styles.imageChosen}>
                  <img src={imageFileLink} alt="boardDorctormmage" />
                  <input
                    type="file"
                    name="image"
                    accept="image/*"
                    onChange={(e) => handleImageChange(e)}
                  />
                </div>
              ) : (
                <span>
                  <IoMdAddCircleOutline /> Choose image
                  <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => handleImageChange(e)}
                  />
                </span>
              )}
            </div>
            {imageErrorMsg && (
              <span className={styles.errorMsg}>Please choose an image</span>
            )}
          </div>
          <button type="submit">
            {isLoading ? "Adding information..." : "Add information"}
          </button>
        </form>
      </div>
    </div>
  );
};
export default AddKeyStaffInfoPanel;
