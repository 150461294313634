import { IoMdAddCircleOutline, IoMdCloseCircleOutline } from "react-icons/io";
import styles from "../../styles/editBODInfoPanel.module.scss";
import { useEffect, useState } from "react";
import {
  useGetResourcesById,
  usePutResources,
  useResources,
} from "../../all-pages/api/apiClient";
import { useNavigate } from "react-router";

const EditResourcesInfoPanel = ({ editItemId, visibility, setVisibility }) => {
  const navigate = useNavigate();
  const putResources = usePutResources();
  const getResourcesByID = useGetResourcesById();
  const [content, setContent] = useState({
    title: "",
    bokLink: "",
    image: "",
  });
  const [updatedContent, setUpdatedContent] = useState({
    title: "",
    bookLink: "",
    image: "",
  });
  const [isEditClick, setIsEditClick] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getResourcesByID(editItemId)
      .then((response) => {
        console.log(response.data);
        setContent(response.data.data);
        setUpdatedContent({
          title: response.data.data.title,
          bookLink: response.data.data.bookLink,
          image: response.data.data.image,
        });
        setImageFile(response.data.data.image);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [isEditClick]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setUpdatedContent((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  };

  // State to check if image is chosen
  const [isImageChosen, setIsImageChosen] = useState(false);

  //   State for image file
  const [imageFile, setImageFile] = useState("");

  //   Error messages states
  const [imageErrorMsg, setImageErrorMsg] = useState(false);
  const [nameErrorMsg, setnameErrorMsg] = useState(false);
  const [bookLinkErrorMsg, setbookLinkErrorMsg] = useState(false);

  const handleImageChange = (e) => {
    // Log image file chosen
    // console.log(e.target.files[0]);
    // console.log(updatedContent);

    // Set the image url
    if (e.target.files[0]) {
      // Create object url
      const imgURL = URL.createObjectURL(e.target.files[0]);

      //   Set image file
      setImageFile(imgURL);
      //   Update boolean value for image chosen
      setIsImageChosen(true);
      // set updated image
      setUpdatedContent((prevValue) => {
        return {
          ...prevValue,
          ["image"]: e.target.files[0],
        };
      });
      //   Close image error message
      setImageErrorMsg(false);
    }
    // If no file was selected
    else {
      setImageFile(undefined);
      //   Update boolean value for image chosen
      setIsImageChosen(false);
      //   SHow image error message
      setImageErrorMsg(true);
    }
  };

  async function validateFormFields(e) {
    // Prevent default behaviour
    e.preventDefault();

    // If title or description or image is unavailable
    if (
      !content.title ||
      !content.bookLink ||
      !imageFile ||
      !updatedContent.title ||
      !updatedContent.bookLink ||
      !updatedContent
    ) {
      !content.title ? setnameErrorMsg(true) : setnameErrorMsg(false);
      !content.bookLink
        ? setbookLinkErrorMsg(true)
        : setbookLinkErrorMsg(false);
      !imageFile ? setImageErrorMsg(true) : setImageErrorMsg(false);
      !updatedContent.title ? setnameErrorMsg(true) : setnameErrorMsg(false);
      !updatedContent.bookLink
        ? setbookLinkErrorMsg(true)
        : setbookLinkErrorMsg(false);

      return;
    }

    // Else...
    // Run function to update info
    console.log(updatedContent);
    setIsLoading(true);
    await putResources(updatedContent, editItemId)
      .then((response) => {
        console.log("response: ", response.data);
        window.location.reload();
      })
      .catch((error) => {
        if (error.response.data.error === "jwt expired") {
          alert("Faild to update content! Login Expired please login again");
        } else {
          if (error.message === "Network Error") {
            alert("Network error! please check your internet connection");
          } else if (error.response.data.error === "jwt expired") {
            alert("Failed to update content: Login Expired please login again");
            navigate("/login");
          } else {
            console.log(error);
          }
        }
      })
      .finally((error) => {
        // setLoading(false);
      });
    setVisibility(false);
  }

  return (
    <div className={styles.rightSidebarContainer}>
      <div
        className={styles.overlay}
        onClick={() => setVisibility(false)}
      ></div>
      <div
        className={`${styles.editBoardOfDirectorPanelContainer} ${styles.container}`}
      >
        <div className={styles.topArea}>
          <div className={styles.topArea__lhs}>
            <h4>Edit Resources Information</h4>
          </div>
          <div className={styles.topArea__rhs}>
            <button onClick={() => setVisibility(false)}>
              <IoMdCloseCircleOutline /> Close
            </button>
          </div>
        </div>
        <form className={styles.infoArea} onSubmit={validateFormFields}>
          <div className={styles.fieldControl}>
            <label htmlFor="title">title:</label>
            <input
              type="text"
              name="title"
              placeholder="Enter Title"
              value={updatedContent.title}
              onChange={handleChange}
            />
            {nameErrorMsg && (
              <span className={styles.errorMsg}>Please enter name</span>
            )}
          </div>
          <div className={styles.fieldControl}>
            <label htmlFor="text">bookLink:</label>
            <textarea
              type="text"
              name="bookLink"
              placeholder="Enter Description"
              value={updatedContent.bookLink}
              onChange={handleChange}
            />
            {bookLinkErrorMsg && (
              <span className={styles.errorMsg}>Please enter Mision</span>
            )}
          </div>
          <div className={styles.fieldControl}>
            <div className={styles.imageControl}>
              <div className={styles.imageChosen}>
                <img src={imageFile || content.image} alt="nametextImage" />
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleImageChange}
                />
              </div>
            </div>
            {imageErrorMsg && (
              <span className={styles.errorMsg}>Please choose an image</span>
            )}
          </div>
          <button type="submit">
            {isLoading
              ? "Please wait, uploading content..."
              : "Update information"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default EditResourcesInfoPanel;
