import React, { useEffect, useState } from "react";
import "./keyStaff.scss";
import "../../about-us/board-of-directors/boardOfDirectors.scss";
import DeletionConfirmationModal from "../../../components/modal/DeleteConfirmationModal";
import EditKeyStaffInfoPanel from "../../../components/sidebar/EditKeyStaffInfoPanel";
import AddKeyStaffInfoPanel from "../../../components/sidebar/AddKeyStaffInfoPanel";
import { boardOfDirectors } from "../../../assets/data";
import {
  useDeleteKeyStaff,
  useGetKeyStaff,
  useKeyStaffs,
} from "../../api/apiClient";

const KeyStaff = () => {
  const getKeyStaff = useGetKeyStaff();
  const deleteKeyStaff = useDeleteKeyStaff();
  const [dataFetched, setDataFetched] = useState(false);
  const [deleteKeyStaffModal, setDeleteKeyStaffModal] = useState(false);
  const [keyStaffPanelVisibility, setKeyStaffPanelVisibility] = useState(false);
  const [addKeyStaffPanelVisibility, setAddKeyStaffPanelVisibility] =
    useState(false);
  const [keyStaffData, setKeyStaffData] = useState([]);

  const [deleteItemId, setDeleteItemId] = useState(null);
  const [editItemId, setEditItemId] = useState(null);

  const handleDeleteKeyStaff = async () => {
    // run function
    console.log("Delete item with ID:", deleteItemId);
    await deleteKeyStaff(deleteItemId)
      .then((response) => {
        console.log(response.data);
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });

    // Close modal
    setDeleteKeyStaffModal(false);
  };

  useEffect(() => {
    getKeyStaff()
      .then((response) => {
        setKeyStaffData(response.data.data);
        setDataFetched(true);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);
  return (
    <>
      <DeletionConfirmationModal
        visibility={deleteKeyStaffModal}
        setVisibility={setDeleteKeyStaffModal}
        contentType={"Key Staff information"}
        deleteFunction={handleDeleteKeyStaff}
      />
      {addKeyStaffPanelVisibility && (
        <AddKeyStaffInfoPanel
          visibility={addKeyStaffPanelVisibility}
          setVisibility={setAddKeyStaffPanelVisibility}
        />
      )}
      {keyStaffPanelVisibility && (
        <EditKeyStaffInfoPanel
          visibility={keyStaffPanelVisibility}
          setVisibility={setKeyStaffPanelVisibility}
          editItemId={editItemId}
        />
      )}
      {dataFetched ? (
        <div className="innerPage boardofdirectors">
          <p
            className="add__board"
            onClick={() => setAddKeyStaffPanelVisibility(true)}
          >
            Add KeyStaff +
          </p>
          <h1>Key Staff</h1>
          <h4>
            Here are some of the key staff who lead our vision towards creating
            <br /> a society full of integrity.
          </h4>
          <div className="boardofdirectors__container">
            {keyStaffData.map(({ image, name, text, position, _id }) => {
              return (
                <div className="boardofdirectors__container-inside" key={_id}>
                  <div className="boardofdirectors__image">
                    <img src={image} alt="" />
                  </div>
                  <h3>
                    {name} - {position}
                  </h3>
                  <p>{text}</p>
                  <div className="modify">
                    <button
                      className="edit-btn"
                      onClick={() => {
                        setEditItemId(_id);
                        setKeyStaffPanelVisibility(true);
                      }}
                    >
                      Edit
                    </button>
                    <button
                      className="delete-btn"
                      onClick={() => {
                        setDeleteItemId(_id);
                        setDeleteKeyStaffModal(true);
                      }}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <div className="innerPage boardofdirectors">Loading data...</div>
      )}
    </>
  );
};

export default KeyStaff;
