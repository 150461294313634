import { useEffect, useState } from "react";
import image from "../../../assets/imgTemplate.jpg";
import "./visionMission.scss";
import EditVisionMission from "../../../components/sidebar/EditVisionMission";
import { useGetVisionMision } from "../../api/apiClient";
const VisionMission = () => {
  const getVisionMissionData = useGetVisionMision();
  const [isLoading, setIsLoading] = useState(true);
  const [loadingMsg, setLoadingMsg] = useState("Loading content. . .");
  const [content, setContent] = useState({});
  const [visionMissionPanelVisibility, setVisionMissionPanelVisibility] =
    useState(false);

  useEffect(() => {
    getVisionMissionData()
      .then((response) => {
        setContent(response.data.data);
        setIsLoading(false);
      })
      .catch((error) => {
        if (error.message === "Network Error") {
          setLoadingMsg("Network error! please check your internet connection");
        } else {
          console.log(error);
        }
      });
  }, []);

  return (
    <>
      {visionMissionPanelVisibility && (
        <EditVisionMission
          visibility={visionMissionPanelVisibility}
          setVisibility={setVisionMissionPanelVisibility}
        />
      )}
      {isLoading ? (
        <p>{loadingMsg}.</p>
      ) : (
        <div className="innerPage visionmission__container">
          <h1>Our Vision and Mission</h1>
          <div className="visionmission__container-vision">
            <div className="visionmission__container-right">
              <div className="flex-container">
                <h3>Our Vision</h3>
                <p>{content.vision}</p>
              </div>
              <div className="flex-container">
                <h3>Our Mission</h3>
                <p>{content.mission}</p>
              </div>
            </div>
            <div className="visionmission__container-left">
              <div className="vision__mission-container">
                <img src={content.image} alt="vision__mision" />
              </div>
            </div>
          </div>
          <button
            className="edit-btn"
            onClick={() => setVisionMissionPanelVisibility(true)}
          >
            Edit
          </button>
        </div>
      )}
    </>
  );
};

export default VisionMission;
