import React, { useState, useEffect } from "react";
import "../projects/macn/macn.scss";
import "./blog.scss";
import DeletionConfirmationModal from "../../components/modal/DeleteConfirmationModal";
import { useNavigate } from "react-router-dom";
import { useDeleteBlog, useGetBlog } from "../api/apiClient";
import AddBloginfoPanel from "../../components/sidebar/AddBloginfoPanel";
import EditBloginfoPanel from "../../components/sidebar/EditBloginfoPanel";
const Blog = () => {
  const getBlog = useGetBlog();
  const deleteBlog = useDeleteBlog();
  const navigate = useNavigate();
  const [deleteModalVisibility, setDeleteModalVisibility] = useState(false);
  const [MACNPanelVisibility, setMACNEditPanelVisibility] = useState(false);
  const [addPanelVisibility, setAddPanelVisibility] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [content, setContent] = useState({});
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [editItemId, setEditItemId] = useState(null);

  const handleDeleteBoardOfDirector = async () => {
    // run function
    console.log("Delete item with ID:", deleteItemId);
    await deleteBlog(deleteItemId)
      .then((response) => {
        console.log(response.data);
        window.location.reload();
      })
      .catch((error) => {
        if (error.message === "Network Error") {
          alert("Network error! please check your internet connection");
        } else if (error.response.data.error === "jwt expired") {
          alert("Login Expired please login again");
          navigate("/");
        } else {
          console.log(error);
        }
      });

    // Close modal
    setDeleteModalVisibility(false);
  };

  useEffect(() => {
    getBlog()
      .then((response) => {
        setContent(response.data.data);
        setIsLoading(false);
      })
      .catch((error) => {
        if (error.message === "Network Error") {
          alert("Network error! please check your internet connection");
        } else {
          console.log(error);
        }
      });
  }, []);

  function formatDate(createdDate) {
    const dateObject = new Date(createdDate);
    const options = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      timeZone: "UTC",
    };
    return dateObject.toLocaleString(undefined, options);
  }

  return (
    <>
      <DeletionConfirmationModal
        visibility={deleteModalVisibility}
        setVisibility={setDeleteModalVisibility}
        contentType={"Blog information"}
        deleteFunction={handleDeleteBoardOfDirector}
      />

      {addPanelVisibility && (
        <AddBloginfoPanel
          visibility={addPanelVisibility}
          setVisibility={setAddPanelVisibility}
        />
      )}
      {MACNPanelVisibility && (
        <EditBloginfoPanel
          editItemId={editItemId}
          visibility={MACNPanelVisibility}
          setVisibility={setMACNEditPanelVisibility}
        />
      )}
      {isLoading ? (
        <p>Loading...</p>
      ) : (
        <div className="innerPage ">
          <p className="add__board" onClick={() => setAddPanelVisibility(true)}>
            Add Blog +
          </p>
          <div className="macn__container">
            {/* {[...Array(13)].map((each, index) => ( */}
            {content.map(
              ({ title, text, createdAt, updatedAt, _id, blogImage }) => (
                // <>
                <div className="blog__container" key={_id}>
                  <img
                    className="blogImage"
                    src={blogImage}
                    alt={title[3] + "img"}
                  />
                  <div className="text-overlay">
                    <div className="created">
                      <p>Created at-{formatDate(createdAt)}</p>
                      <p>Edited at {formatDate(updatedAt)}</p>
                    </div>
                    <h3>
                      {title.length <= 30
                        ? title
                        : title.substring(0, 30) + "..."}
                    </h3>
                    <div className="modify">
                      <button
                        className="edit-btn"
                        onClick={() => {
                          setEditItemId(_id);
                          setMACNEditPanelVisibility(true);
                        }}
                      >
                        Edit
                      </button>
                      <button
                        className="delete-btn"
                        onClick={() => {
                          setDeleteItemId(_id);
                          setDeleteModalVisibility(true);
                        }}
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
              )
            )}
          </div>
        </div>
      )}
    </>
  );
};
export default Blog;
