import React, { useEffect, useState } from "react";
import "./boardOfDirectors.scss";
import { boardOfDirectors } from "../../../assets/data";
import DeletionConfirmationModal from "../../../components/modal/DeleteConfirmationModal";
import EditBoardOfDirectorsInfoPanel from "../../../components/sidebar/EditBoardOfDirectorsInfoPanel";
import AddBoardOfDirectorsInfoPanel from "../../../components/sidebar/AddBoardOfDirectorsInfoPanel";
import { useDeleteBOD, useGetBoardOfDirectors } from "../../api/apiClient";

const BoardOfDirectors = () => {
  const getBODData = useGetBoardOfDirectors();

  const [boardOfDirectors1, setBoardOfDirectors] = useState([]);
  const [dataFetched, setDataFetched] = useState(false);
  const deleteBOD = useDeleteBOD();

  const [deleteModalVisibility, setDeleteModalVisibility] = useState(false);
  const [boardOfDirectorPanelVisibility, setBoardOfDirectorPanelVisibility] =
    useState(false);
  const [
    addBoardOfDirectorPanelVisibility,
    setAddBoardOfDirectorPanelVisibility,
  ] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [editItemId, setEditItemId] = useState(null);

  const handleDeleteBoardOfDirector = async () => {
    // Delete logic using Axios or your preferred method
    console.log("Delete item with ID:", deleteItemId);
    await deleteBOD(deleteItemId)
      .then((response) => {
        console.log(response.data);
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });

    // Close modal
    setDeleteModalVisibility(false);
  };

  useEffect(() => {
    getBODData()
      .then((response) => {
        setBoardOfDirectors(response.data.data);
        setDataFetched(true);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);
  return (
    <>
      <DeletionConfirmationModal
        visibility={deleteModalVisibility}
        setVisibility={setDeleteModalVisibility}
        contentType={"Board of director's information"}
        deleteFunction={handleDeleteBoardOfDirector}
      />

      {addBoardOfDirectorPanelVisibility && (
        <AddBoardOfDirectorsInfoPanel
          visibility={addBoardOfDirectorPanelVisibility}
          setVisibility={setAddBoardOfDirectorPanelVisibility}
        />
      )}
      {boardOfDirectorPanelVisibility && (
        <EditBoardOfDirectorsInfoPanel
          visibility={boardOfDirectorPanelVisibility}
          setVisibility={setBoardOfDirectorPanelVisibility}
          documentId={editItemId}
        />
      )}

      {dataFetched ? (
        <div className="innerPage boardofdirectors">
          <p
            className="add__board"
            onClick={() => setAddBoardOfDirectorPanelVisibility(true)}
          >
            Add board of directors +
          </p>
          <h1>The CBI Board of Directors</h1>
          <h4>
            Here are some of the key CBI directors who lead our vision <br />
            towards creating a society full of integrity.
          </h4>
          <div className="boardofdirectors__container">
            {boardOfDirectors1.map(({ image, name, text, _id }) => {
              return (
                <div className="boardofdirectors__container-inside" key={_id}>
                  <div className="boardofdirectors__image">
                    <img src={image} alt="" />
                  </div>
                  <h3>{name}</h3>
                  <p>{text}</p>
                  <div className="modify">
                    <button
                      className="edit-btn"
                      onClick={() => {
                        setEditItemId(_id);
                        setBoardOfDirectorPanelVisibility(true);
                      }}
                    >
                      Edit
                    </button>
                    <button
                      className="delete-btn"
                      onClick={() => {
                        setDeleteItemId(_id);
                        setDeleteModalVisibility(true);
                      }}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <div className="innerPage boardofdirectors">Loading data...</div>
      )}
    </>
  );
};

export default BoardOfDirectors;
