import "./register.scss";
import IntegrityLogo from "../../assets/aaj.png";
import { useAuth, useRegister } from "../api/apiClient";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { NavLink } from "react-router-dom";
import CustomPreloader from "../../components/preloader/preloader";

const Register = () => {
  const registerUser = useRegister();
  const authUser = useAuth();
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("admin");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);

  const [formValues, setFormValues] = useState({});

  const navigate = useNavigate();
  useEffect(() => {
    authUser()
      .then((response) => {
        console.log(response.data.data);
        if (response.data.data.role === "superAdmin") {
          setIsSuperAdmin(true);
        } else if (response.data.data.role === "admin") {
          setErrorMsg("Please log in as a super admin to register");
          console.log("true");
        }
        // console.log("Not super");
      })
      .catch((error) => {
        if (error.message === "Network Error") {
          setErrorMsg("Network error! please check your internet connection");
        } else {
          console.log(error);
        }
      });
  }, []);

  useEffect(() => {
    setFormValues({
      firstName: firstName,
      lastName: lastName,
      userName: userName,
      email: email,
      password: password,
      role: role,
      phoneNumber: phoneNumber,
    });
  }, [firstName, lastName, userName, email, password, role, phoneNumber]);

  async function handleUserRegistration(e) {
    setErrorMsg("");
    e.preventDefault();

    console.log(formValues);

    // Validate fields
    if (
      !firstName ||
      !lastName ||
      !userName ||
      !email ||
      !password ||
      !role ||
      !phoneNumber
    ) {
      if (!firstName) {
        setErrorMsg("Please input your firstname");
        return;
      }
      if (!lastName) {
        setErrorMsg("Please input your lastname");
        return;
      }
      if (!userName) {
        setErrorMsg("Please input your phone Username");
        return;
      }
      if (!phoneNumber) {
        setErrorMsg("Please input your phone number");
        return;
      }
      if (!email) {
        setErrorMsg("Please input your email");
        return;
      }
      if (!password) {
        setErrorMsg("Please input your password");
        return;
      }
      if (!email) {
        setErrorMsg("Please input your email");
        return;
      }

      setErrorMsg(undefined);
      return;
    }

    setLoading(true);
    // Send request
    await registerUser(formValues)
      .then((response) => {
        console.log("response: ", response.data);
        if (response.data.success) {
          setErrorMsg("");
          // Delay the navigation for 5 seconds
          setTimeout(() => {
            setLoading(true);
          }, 5000);
          navigate("/admin");
        }
      })
      .catch((error) => {
        if (error.response) {
          setErrorMsg(error.response.data.error);
        } else {
          setErrorMsg(
            <>
              There was an error Registering you in.
              <br /> Check your internet connection
            </>
          );
        }
        console.log("error: ", error);
      })
      .finally((error) => {
        setLoading(false);
      });
  }

  return (
    <>
      {loading && <CustomPreloader />}
      {isSuperAdmin ? (
        <div className="register__container">
          <div className="logo__container">
            <img src={IntegrityLogo} alt="integrity Logo" />
          </div>
          <form
            className="register__section"
            onSubmit={(e) => handleUserRegistration(e)}
          >
            <h3>Register</h3>
            <span></span>
            <div className="f-c input__container">
              <label>First name</label>
              <input
                type="text"
                placeholder="Please enter first name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </div>
            <div className="f-c input__container">
              <label>Last name</label>
              <input
                type="text"
                placeholder="Please enter last name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
            <div className="f-c input__container">
              <label>Username</label>
              <input
                type="text"
                placeholder="Please enter username"
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
              />
            </div>
            <div className="f-c input__container">
              <label>Phone Number</label>
              <input
                type="text"
                placeholder="Please enter phone number"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
            </div>
            <div className="f-c input__container">
              <label>Email Address</label>
              <input
                type="email"
                placeholder="Please enter email address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="f-c input__container">
              <label>Password</label>
              <input
                type="text"
                placeholder="Please enter password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className="register__reset">
              <p>Can't login? Reset Department here</p>
              <p>Forgot Password</p>
            </div>

            {errorMsg && <div className="errorMsg">{errorMsg}</div>}

            <button className="btn" type="submit">
              {loading ? "Loading..." : "Register"}
            </button>
          </form>
        </div>
      ) : (
        <p className="register__container">
          <NavLink to="/" className="add__board">
            "Log in as a super admin to register. . ."
          </NavLink>
          <button className="btn">Log in as Super Admn</button>
        </p>
      )}
    </>
  );
};

export default Register;
