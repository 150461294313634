import { IoMdCloseCircleOutline, IoMdAddCircleOutline } from "react-icons/io";
import styles from "../../styles/editBODInfoPanel.module.scss";
import React, { useState, useRef, useMemo } from "react";
import "jodit";
import "jodit/build/jodit.min.css";
import JoditEditor from "jodit-react";
import {
  useCreateBlogInformation,
  useGetURL,
} from "../../all-pages/api/apiClient";

import { useNavigate } from "react-router-dom";
const AddBloginfoPanel = ({ visibility, setVisibility }) => {
  const navigate = useNavigate();
  const editor = useRef(null);
  const createBlogInformation = useCreateBlogInformation();
  const getURL = useGetURL();
  const config = useMemo(
    () => ({
      readonly: false, // all options from https://xdsoft.net/jodit/docs/,
      placeholder: "Start typings...",
    })
    // [placeholder]
  );

  // State to check if image is chosen
  const [isImageChosen, setIsImageChosen] = useState(false);
  const [isBlogImageChosen, setIsBlogImageChosen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [fetchedImage, setFetchedImage] = useState(false);

  //   State for image file
  const [image, setImage] = useState();
  const [blogImage, setBlogImage] = useState();
  const [imageFileLink, setImageFileLink] = useState();
  const [blogImageFileLink, setBlogImageFileLink] = useState();

  const [addDetails, setaddDetails] = useState({
    title: "",
  });

  const [content, setContent] = useState("");

  //   Error messages states
  const [imageErrorMsg, setImageErrorMsg] = useState(false);
  const [blogImageErrorMsg, setBlogImageErrorMsg] = useState(false);
  const [nameErrorMsg, setNameErrorMsg] = useState(false);
  const [contentErrorMsg, setContentErrorMsg] = useState(false);

  const handleImageChange = async (e) => {
    // Set the image url
    if (e.target.files[0]) {
      // Create object url
      const imgURL = URL.createObjectURL(e.target.files[0]);
      setImageFileLink(imgURL);

      //   Set image file
      setImage(e.target.files[0]);
      //   Update boolean value for image chosen
      setIsImageChosen(true);
      //   Close image error message
      setImageErrorMsg(false);

      // Run function to add info
      const dataToSend = {
        image: e.target.files[0],
      };

      console.log(dataToSend);
      await getURL(dataToSend)
        .then((response) => {
          console.log("response: image link generated");
          setFetchedImage(response.data.data.url);
          // window.location.reload();
        })
        .catch((error) => {
          console.log("error: ", error);
          setFetchedImage("There was an error fetching image");
          if (error.message === "Network Error") {
            alert("Network error! please check your internet connection");
          } else if (error.response.data.error === "jwt expired") {
            alert("Login Expired please login again");
            navigate("/login");
          }
        })
        .finally((error) => {
          // setLoading(false);
        });
    }
    // If no file was selected
    else {
      setImage(undefined);
      //   Update boolean value for image chosen
      setIsImageChosen(false);
      //   SHow image error message
      setImageErrorMsg(true);
    }
  };

  const handleBlogImageChange = async (e) => {
    // Set the image url
    if (e.target.files[0]) {
      // Create object url
      const imgURL = URL.createObjectURL(e.target.files[0]);
      setBlogImageFileLink(imgURL);

      //   Set image file
      setBlogImage(e.target.files[0]);
      // console.log(blogImage);
      //   Update boolean value for image chosen
      setIsBlogImageChosen(true);
      //   Close image error message
      setBlogImageErrorMsg(false);

      // Run function to add info
      const dataToSend = {
        image: e.target.files[0],
      };

      console.log(dataToSend);
      // await getURL(dataToSend)
      //   .then((response) => {
      //     console.log("response: image link generated");
      //     setFetchedImage(response.data.data.url);
      //     // window.location.reload();
      //   })
      //   .catch((error) => {
      //     console.log("error: ", error);
      //     setFetchedImage("There was an error fetching image");
      //     if (error.message === "Network Error") {
      //       alert("Network error! please check your internet connection");
      //     } else if (error.response.data.error === "jwt expired") {
      //       alert("Login Expired please login again");
      //       navigate("/login");
      //     }
      //   })
      //   .finally((error) => {
      //     // setLoading(false);
      //   });
    }
    // If no file was selected
    else {
      setBlogImage(undefined);
      //   Update boolean value for image chosen
      isBlogImageChosen(false);
      //   SHow image error message
      setBlogImageErrorMsg(true);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    setaddDetails((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  };

  async function validateFormFields(e) {
    // Prevent default behaviour
    setIsLoading(true);
    e.preventDefault();
    const dataToSend = {
      title: addDetails.title,
      text: content,
      blogImage: blogImage,
    };
    // console.log(dataToSend);

    // If title or content or image is unavailable
    if (!dataToSend.title || !dataToSend.text || !blogImage) {
      !dataToSend.title ? setNameErrorMsg(true) : setNameErrorMsg(false);
      !dataToSend.text ? setContentErrorMsg(true) : setContentErrorMsg(false);
      !dataToSend.image
        ? setBlogImageErrorMsg(true)
        : setBlogImageErrorMsg(false);
      setIsLoading(false);

      return;
    }

    // console.log("This is the data i'm sending: ", dataToSend);
    // Run function to add info
    await createBlogInformation(dataToSend)
      .then((response) => {
        console.log("response: ", response.data);
        window.location.reload();
      })
      .catch((error) => {
        if (error.message === "Network Error") {
          alert("Network error! please check your internet connection");
        } else if (error.response.data.error === "jwt expired") {
          alert("Failed to update content: Login Expired please login again");
          navigate("/login");
        } else {
          console.log(error);
        }
      })
      .finally((error) => {
        // setLoading(false);
      });

    // Clsoe panel
    setVisibility(false);
    setIsLoading(false);
  }

  return (
    <div className={styles.rightSidebarContainer}>
      <div
        className={styles.overlay}
        onClick={() => setVisibility(false)}
      ></div>
      <div
        className={`${styles.editBoardOfDirectorPanelContainer} ${styles.container}`}
      >
        <div className={styles.topArea}>
          <div className={styles.topArea__lhs}>
            <h4>Add Blog Information</h4>
          </div>
          <div className={styles.topArea__rhs}>
            <button onClick={() => setVisibility(false)}>
              <IoMdCloseCircleOutline /> Close
            </button>
          </div>
        </div>
        <form className={styles.infoArea} onSubmit={validateFormFields}>
          <div className={styles.fieldControl}>
            <label htmlFor="name">Title</label>
            <input
              type="text"
              name="title"
              placeholder="Enter Title"
              value={addDetails.title}
              onChange={handleChange}
            />
            {nameErrorMsg && (
              <span className={styles.errorMsg}>Please enter title</span>
            )}
          </div>
          <div className={styles.fieldControl}>
            <p>Add blog image</p>
            <div className={styles.imageControl}>
              {isBlogImageChosen ? (
                <div className={styles.imageChosen}>
                  <img src={blogImageFileLink} alt="boardDorctormmage" />
                  <input
                    type="file"
                    name="image"
                    accept="image/*"
                    onChange={(e) => handleBlogImageChange(e)}
                  />
                </div>
              ) : (
                <span>
                  <IoMdAddCircleOutline /> Choose image
                  <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => handleBlogImageChange(e)}
                  />
                </span>
              )}
            </div>
            {blogImageErrorMsg && (
              <span className={styles.errorMsg}>
                Please choose a blog image
              </span>
            )}
          </div>
          <div className={styles.fieldControl}>
            <label htmlFor="position">
              {fetchedImage &&
                "Image Link generatd copy and paste to the text editor"}
            </label>
            {imageFileLink ? (
              <input
                type="text"
                name="bookLink"
                placeholder={"ddh"}
                value={fetchedImage ? fetchedImage : "Generating URL..."}
                onChange={(e) => setFetchedImage(fetchedImage)}
              />
            ) : (
              <p>Select an image to view url</p>
            )}
          </div>
          <div className={styles.fieldControl}>
            <div className={styles.imageControl}>
              {isImageChosen ? (
                <div className={styles.imageChosen}>
                  <img src={imageFileLink} alt="boardDorctormmage" />
                  <input
                    type="file"
                    name="image"
                    accept="image/*"
                    onChange={(e) => handleImageChange(e)}
                  />
                </div>
              ) : (
                <span>
                  <IoMdAddCircleOutline /> Choose image
                  <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => handleImageChange(e)}
                  />
                </span>
              )}
            </div>
            {imageErrorMsg && (
              <span className={styles.errorMsg}>Please choose an image</span>
            )}
          </div>

          {/* <div className={styles.fieldControl}>{TextEditor()}</div> */}
          <div className={styles.fieldControl}>
            <JoditEditor
              // ref={editor}
              value={content}
              // config={config}
              tabIndex={2} // tabIndex of textarea
              onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
              // onChange={(newContent) => {}}
              // onChange={(e) => setContent(e.target.value)}
            />
            {contentErrorMsg && (
              <span className={styles.errorMsg}>Please enter content</span>
            )}
          </div>
          <button type="submit">
            {isLoading ? "Adding information..." : "Add information"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default AddBloginfoPanel;
